import { Injectable, signal } from '@angular/core';
import { ChatMessage } from '../models/chat-message';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ChatWsService {
  private ws: WebSocket | null = null;
  private reconnectAttempts = 0;
  private baseReconnectDelay = 1000; // Start with 1 second
  private reconnectTimeout: any;

  chatStream = new BehaviorSubject<ChatMessage | null>(null);
  chatStream$ = this.chatStream.asObservable();
  wsConnected = signal(false);

  constructor() {
    this.connect();
  }

  connect() {
    if (this.ws?.readyState === WebSocket.CONNECTING) return;

    const url = environment.chatWsUrl + localStorage.getItem('token');
    this.ws = new WebSocket(url);

    this.ws.onopen = () => {
      this.wsConnected.set(true);
      this.reconnectAttempts = 0;
    };

    this.ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      this.chatStream.next(data as ChatMessage);
    };

    this.ws.onclose = (event) => {
      console.log('WebSocket closed:', event);
      this.wsConnected.set(false);

      if (!event.wasClean) {
        this.handleReconnect();
      }
    };

    this.ws.onerror = (event) => {
      console.error('WebSocket error:', event);
      this.wsConnected.set(false);
    };
  }

  private handleReconnect() {
    const maxDelay = 300000; // Maximum delay of 5 minutes
    const delay = Math.min(
      this.baseReconnectDelay * Math.pow(2, this.reconnectAttempts),
      maxDelay,
    );

    console.log(`Attempting to reconnect in ${delay / 1000} seconds...`);

    this.reconnectTimeout = setTimeout(() => {
      this.reconnectAttempts++;
      this.connect();
    }, delay);
  }

  sendMessage(message: ChatMessage): void {
    if (this.ws?.readyState === WebSocket.OPEN) {
      this.ws.send(JSON.stringify(message));
    } else {
      console.warn('WebSocket is not connected. Message not sent:', message);
    }
  }

  close() {
    if (this.ws) {
      clearTimeout(this.reconnectTimeout);
      this.ws.close();
      this.ws = null;
    }
  }
}
